.thirdContainer {
  background-color: #003049;
  display: grid;
  grid-template-columns: 4vw 20vw auto auto 20vw 4vw;
  grid-template-rows: 4vh 10vh auto 10vh 14vh;
  /* min-height: 100vh; */
}

.thirdOuterBorder {
  grid-column: 1 / 7;
  grid-row: 1 / 8;
  margin: 0px 3vw 3vw 3vw;
  border-left: 5px solid #eae2b7;
  border-bottom: 5px solid #eae2b7;
  border-right: 5px solid #eae2b7;
}

.aboutMe {
  /* border: 2px solid black; */
  /* layout */
  grid-column: 3 / 5;
  grid-row: 2 / 2;
  color: #eae2b7;
  /* position */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.aboutMeTitle {
  /* sizes */
  font-size: 46px;
  font-weight: bold;
}

@media (max-width: 600px) {
  .aboutMeTitle {
    font-size: 36px;
  }
}

.aboutMeContainer {
  /* border: 5px solid black; */
  color: #eae2b7;
  grid-column: 4 / 5;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: minmax(1, auto);
  grid-template-areas: "image" "item" "timeline" "timeline-text";
  /* border: 5px solid red; */
}

.aboutMeImage {
  /* justify-content: center; */
  /* align-items: center; */
  grid-area: image;
  /* border: 5px solid yellow; */
  margin: auto;
}

.aboutMeItem {
  grid-area: item;
  /* border: 5px solid yellow; */
  font-size: 16px;
  text-align: center;
}

.aboutMeText {
  text-align: left;
  line-height: 25px;
}

.social {
  margin-top: 5vh;
  /* border: 5px solid white; */
}

a:link {
  color: #51abff;
}

a:visited {
  color: #51abff;
}

a:active {
  color: #3877b5;
}

a:hover {
  color: #3877b5;
}
