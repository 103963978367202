.onePostContainer {
    background-color: #EAE2B7 ;
    min-height: 100vh;
    padding: 2%;
}

.borders {
    padding: 2% 2% 4% 2%;
    border: 5px solid #003049;
}

.content {
    margin: 0 20%;
}

.blockContent > figure > img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
}

@media (max-width: 600px) {
    .content {
        margin: 0 1%;
        width: 100%;
    }
    
}

.postDataTitle {
    font-size: 46px;
    font-weight: bold;
    color: #003049;
}

.backButton {
    cursor: pointer;
    font-weight: bolder;
    text-decoration: underline;
    margin-bottom: 15px;
    width: fit-content;
}