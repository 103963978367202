html {
    scroll-behavior: smooth;
}

.container {
    display: grid;
    grid-template-columns: 4% 10% auto 10% 4%;
    grid-template-rows: auto;
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    // height: calc(var(--vh, 1vh) * 100);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10+ and Edge */
    user-select: none; /* Standard syntax */
    @media (max-width: 1200px) {
        min-height: 0;
    }
}


.innerContainer {
    grid-column: 2 / 5;
    grid-row: 2 / 7;
    height: 100%;
}

.outerBorder {
    grid-column: 1 / 6;
    grid-row: 1 / 8;
    margin: 3vw 3vw 0px 3vw;
    border-left: 5px solid #EAE2B7;
    border-top: 5px solid #EAE2B7;
    border-right: 5px solid #EAE2B7;
    // height: 100vh;
}

.center {
    /* layout */
    grid-column: 2 / 5;
    grid-row: 2 / 6;
    color: #EAE2B7;
    /* position */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    /* sizes */
    font-size: 18px;
    margin: auto;
    text-align: center;
    span {
        font-size: 60px;
    }
}

@media (max-width: 600px) {
    .center{
        span {
            font-size: 40px;
        }
    }
    .container {
        // min-height: 0;
        // height: auto;
        position: relative;
    }
    .outerBorder {
        // min-height: 100vh;
        // height: auto;
    }
}

.cursor {
    background: white !important
}

.bottom {
    position: absolute;
    bottom: 0;
    color:wheat;
}

.arrow {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color:white;
    margin: auto;
    grid-column: 3 / 3;
    grid-row: 6 / 6;
    cursor: pointer;
  }

.hide {
    display: none;
}

.animation {
opacity: 0.5;
-moz-animation: fade-move-down 2s infinite;
-webkit-animation: fade-move-down 2s infinite;
animation: fade_move_down 2.5s ease-in-out infinite;
}

@keyframes fade_move_down {
0%   { transform:translate(0,-15px); opacity: 0;  }
50%  { opacity: 0.7;  }
100% { transform:translate(0,15px); opacity: 0; }
}

@-webkit-keyframes fade_move_down {
0%   { transform:translate(0,-15px); opacity: 0;  }
50%  { opacity: 0.7;  }
100% { transform:translate(0,15px); opacity: 0; }
}
@-moz-keyframes fade_move_down {
0%   { transform:translate(0,-15px); opacity: 0;  }
50%  { opacity: 0.7;  }
100% { transform:translate(0,15px); opacity: 0; }
}