.secondContainer {
  background-color: #eae2b7;
  display: grid;
  grid-template-columns: 4vw 10vw auto auto 10vw 4vw;
  grid-template-rows: 4vh 8vh 10vh auto 10vh 14vh;
  // min-height: 100vh;
}

.secondOuterBorder {
  grid-column: 1 / 7;
  grid-row: 1 / 7;
  margin: 0px 3vw 0 3vw;
  border-left: 5px solid #003049;
  /* border-bottom: 5px solid white; */
  border-right: 5px solid #003049;
}

.projectHighlights {
  /* layout */
  grid-column: 1 / 7;
  grid-row: 1 / 3;
  // color: #EAE2B7;
  /* position */
  text-align: center;
  margin: auto;
  /* sizes */
  // border: 5px solid yellow;
}

.projectTitle {
  font-size: 46px;
  font-weight: bold;
  color: #003049;
}

.itemTitle {
  font-size: 18px;
  font-weight: bold;
}

.itemText {
  font-size: 16px;
}

.gallery {
  grid-column: 3 / 5;
  grid-row: 3 / 7;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 1rem;
  grid-template-areas: "firstItem secondItem" "thirdItem fourthItem";
  min-width: 220px;
  margin: 0 5%;
  justify-items: center;
  justify-content: space-evenly;
  padding-bottom: 30px;
  // border: 5px solid red;
}

.modalContainer {
  width: 50vw;
  height: 90vh;
  // border-radius: 15px;
  padding: 0 35px 25px 35px;
  background-color: #eae2b7;
  text-align: left;
  overflow-y: scroll;
}

.img_scaling {
  max-width: 50vw;
}

@media (max-width: 600px) {
  .projectTitle {
    font-size: 32px;
  }
  .itemTitle {
    font-size: 22px;
    margin-bottom: 2vh;
  }
  .itemText {
    display: none;
  }
  .gallery {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-template-areas: "firstItem" "secondItem" "thirdItem" "fourthItem";
    // grid-gap: 1rem;
    // max-width: 50px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  .modalContainer {
    width: 75vw;
    height: 90vh;
    // border-radius: 15px;
    padding: 0 35px 25px 35px;
  }

  .img_scaling {
    max-width: 75vw;
  }
}

.responsive {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.image {
  max-width: 300px;
  margin: auto;
  &:hover {
    #wave-svg {
      animation-play-state: running;
    }
  }
}

.firstItem {
  // margin: auto;
  max-width: 400px;
  grid-area: firstItem;
  cursor: pointer;
  text-align: center;
  &:hover {
    #wave-svg {
      animation-play-state: running;
    }
  }
}

.secondItem {
  // margin: auto;
  max-width: 400px;
  grid-area: secondItem;
  // grid-row: 2 / 5;
  // border: 5px solid black;
  cursor: pointer;
  text-align: center;
  &:hover {
    #wave-svg {
      animation-play-state: running;
    }
  }
}

.thirdItem {
  grid-area: thirdItem;
  // margin: auto;
  max-width: 400px;
  // grid-row: 5 / 8;
  // border: 5px solid black;
  cursor: pointer;
  text-align: center;
  &:hover {
    #wave-svg {
      animation-play-state: running;
    }
  }
}

.fourthItem {
  // margin: auto;
  max-width: 400px;
  grid-area: fourthItem;
  // grid-row: 5 / 8;
  // border: 5px solid black;
  cursor: pointer;
  text-align: center;
  &:hover {
    #wave-svg {
      animation-play-state: running;
    }
  }
}

#wave-svg {
  position: relative;
  width: 160%;
  animation: wave 0.15s linear infinite;
  animation-play-state: paused;
}

@keyframes wave {
  0% {
    left: -30%;
  }
  100% {
    left: -19%;
  }
}

.svg-overflow {
  overflow: hidden;
  padding: 0 15px;
}

#style-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

#style-1::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.gallery a {
  color: black !important;
  text-decoration: none;
}
