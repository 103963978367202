body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Karla';
  font-weight: 400;
  src: local('Karla'),
       local('Karla-Regular'),
       url(./fonts/Karla-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Karla';
  font-weight: 700;
  src: local('Karla Bold'),
       local('Karla-Bold'),
       url(./fonts/Karla-Bold.ttf) format('truetype');
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
