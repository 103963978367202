body {
  min-width: 320px;
  background-color: #003049;
  font-family: 'Karla';
  margin: 0;
  padding: 0;
  height: 100%;
}
html{
  height:100%;
  }
h1 {
  font-size: 60px;
}
